import colors from '../base/colors';
import { fontSize, fontWeight } from '../base/typography';
import component from '../component';

export default component('MuiFormLabel', {
  defaultProps: {
    focused: false,
  },

  styleOverrides: {
    root: {
      fontSize: fontSize.sm,
      color: colors.dark.main,
      fontWeight: fontWeight.bold,
    },
  },
});
