import { forwardRef } from 'react';
import { NavLink as BaseNavLink, NavLinkProps } from 'react-router-dom';

import usePrefetch from 'hooks/usePrefetch';

interface Props extends NavLinkProps {
  prefetch?: boolean;
}

const NavLink = forwardRef<HTMLAnchorElement, Props>(({ prefetch = false, ...props }, ref) => {
  const { to } = props;

  usePrefetch({ enabled: prefetch, to });

  return <BaseNavLink {...props} ref={ref} />;
});

export default NavLink;
