import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { BoxProps } from '@mui/system';

import { createStyles } from 'utils/styles';

const styles = createStyles({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
});

export default function PageLoader({ sx, ...props }: BoxProps) {
  return (
    <Box sx={{ ...styles, ...sx }} {...props}>
      <CircularProgress />
    </Box>
  );
}
