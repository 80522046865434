import boxShadows from '../base/boxShadows';
import component from '../component';

export default component('MuiMenu', {
  defaultProps: {
    elevation: 0,
  },

  styleOverrides: {
    root: {
      '& .MuiPaper-root': {
        boxShadow: boxShadows.md,
        paddingLeft: '6px',
        paddingRight: '6px',
      },
    },

    list: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
  },
});
