import { useEffect } from 'react';
import { matchRoutes, To, useResolvedPath } from 'react-router-dom';

import { Route } from 'config/routes';
import { useRoutes } from 'contexts/RoutesContext';

interface Props {
  enabled?: boolean;
  to: To;
}

const debug = process.env.NODE_ENV === 'development';

const prefetched: Record<string, boolean> = {};

export default function usePrefetch({ enabled = true, to }: Props) {
  const { routes } = useRoutes();
  const path = useResolvedPath(to);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const cacheKey = path.pathname;
    const isPrefetched = prefetched[cacheKey];

    if (isPrefetched) {
      return;
    }

    const matches = matchRoutes(routes, path) ?? [];
    const match = matches[matches.length - 1];

    if (!match) {
      return;
    }

    import(`routes/${(match.route as Route).route}`)
      .then(() => {
        prefetched[cacheKey] = true;

        if (debug) {
          console.debug(`route '${path.pathname}' prefetched, match:`, match);
        }
      })
      .catch(console.error);
  }, [enabled, path, routes]);
}
