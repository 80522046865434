import React, { createContext, useState, useContext, useEffect } from 'react';

interface DataContextType {
  selectedClient: any;
  selectedBank: any;
  defaultBank: any;
  selectedInvoice: any;
  setSelectedClient: (client: any) => void;
  setSelectedBank: (bank: any) => void;
  setDefaultBank: (bank: any) => void;
  setSelectedInvoice: (invoice: any) => void;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

export function useDataContext(): DataContextType {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useCompanyContext must be used within a CompanyProvider');
  }
  return context;
}

interface DataProviderProps {
  children: React.ReactNode;
}

export function DataProvider({ children }: DataProviderProps) {
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [selectedBank, setSelectedBank] = useState<any>(null);
  const [defaultBank, setDefaultBank] = useState<any>(null);
  const [selectedInvoice, setSelectedInvoice] = useState<any>(null);

  useEffect(() => {
    // Save to local storage whenever selectedInvoice or defaultBank changes
    localStorage.setItem('selectedInvoice', JSON.stringify(selectedInvoice));
    localStorage.setItem('defaultBank', JSON.stringify(defaultBank));
  }, [selectedInvoice, defaultBank]);

  useEffect(() => {
    // Load data from localStorage on component mount
    const storedSelectedInvoice = localStorage.getItem('selectedInvoice');
    if (storedSelectedInvoice) {
      setSelectedInvoice(JSON.parse(storedSelectedInvoice));
    }

    const storedDefaultBank = localStorage.getItem('defaultBank');
    if (storedDefaultBank) {
      setDefaultBank(JSON.parse(storedDefaultBank));
    }
  }, [defaultBank]);

  const value: DataContextType = {
    selectedClient,
    selectedBank,
    defaultBank,
    selectedInvoice,
    setSelectedClient,
    setSelectedBank,
    setDefaultBank,
    setSelectedInvoice,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}
