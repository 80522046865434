import Box from '@mui/material/Box';
import Anchor from '@mui/material/Link';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import { useTranslation } from 'react-i18next';
import * as React from 'react';

import { createStyles } from 'utils/styles';
import LanguageMenu from 'components/LanguageSelect';
import Link from 'components/Link';
import logoBusinessApp from '../../images/business-app-white-horizontal.png';

const links = [
  {
    url: '/sign-up',
    label: 'common:signUp',
    icon: AccountCircleTwoToneIcon,
  },
  {
    url: '/',
    label: 'common:signIn',
    icon: LoginRoundedIcon,
  },
];

const styles = createStyles({
  container: {
    px: 4,
    py: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: ['100%', '100%', '80%', '65%'],
    width: '100%',
    left: 0,
    right: 0,
    mx: 'auto',
    mt: '12px',
    position: 'absolute',
  },

  nav: {
    display: 'flex',
    alignItems: 'center',
  },

  navItem: {
    color: '#fff',
    p: 1,
    mx: 1,
  },

  link: theme => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(14),
  }),

  icon: theme => ({
    mr: 1,
    color: 'inherit',
    fontSize: theme.typography.pxToRem(20),
  }),

  lang: theme => ({
    fontSize: theme.typography.pxToRem(20),
  }),
});

export default function Header() {
  const { t } = useTranslation();

  return (
    <Box display="flex" sx={styles.container}>
      <img src={logoBusinessApp} alt={'Logo'} style={{ height: '80px' }} />
      <Box sx={styles.nav}>
        {links.map(({ url, icon: Icon, label }, index) => (
          <Anchor key={index} component={Link} prefetch to={url} sx={[styles.navItem, styles.link]}>
            <Icon sx={styles.icon} />
            {t(label)}
          </Anchor>
        ))}
        <LanguageMenu sx={[styles.navItem, styles.lang]} />
      </Box>
    </Box>
  );
}
