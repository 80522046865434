/* eslint-disable */
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { AccountBalance } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import ReportIcon from '@mui/icons-material/Report';

import { createStyles, getRgba } from 'utils/styles';
import { useMenu } from 'contexts/MenuContext';
import NavLink from 'components/NavLink';
import { useCompanyContext } from '../../contexts/CompanyContext';
import { useEffect, useState } from 'react';
import ClientService from '../../services/ClientService';
import Collapse from '@mui/material/Collapse';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as React from 'react';
import logoBusinessApp from '../../images/final-business-app-web-logo.png';
import { useTranslation } from 'react-i18next';

const links = [
  // {
  //   label: 'Dashboard',
  //   icon: HomeIcon,
  //   href: '/dashboard',
  // },
  // {
  //   label: 'Customers',
  //   icon: PeopleRoundedIcon,
  //   href: '/customers',
  // },
  {
    label: 'clients',
    icon: PeopleRoundedIcon,
    href: '/clients',
  },
  {
    label: 'invoices',
    icon: DescriptionOutlinedIcon,
    submenu: [
      { label: 'domestic', href: '/invoices/domestic' },
      { label: 'foreign', href: '/invoices/foreign' },
    ],
  },
  {
    label: 'banks',
    icon: AccountBalance,
    href: '/banks',
  },
  {
    label: 'reports',
    icon: ReportIcon,
    href: '/reports',
  },
];

const styles = createStyles({
  header: {
    position: 'relative',
    pt: '16px',
  },

  closeMenuButton: {
    position: 'absolute',
    top: '8px',
    right: '8px',
  },

  button: {
    backgroundColor: 'transparent',
    '&.active': {
      backgroundColor: 'transparent',
      '& .icon': {
        backgroundColor: 'transparent',
        color: '#3acaeb',
      },
    },
  },

  icon: {
    backgroundColor: 'transparent',
    mr: 2,
  },

  label: {
    lineHeight: 'normal',
  },
  invoiceTab: {
    marginBottom: '8px', // Add margin bottom to create space between tabs
  },
});

const drawerWidth = 260;

const Side = styled(Drawer, { shouldForwardProp: prop => prop !== 'isDesktop' })<{
  isDesktop: boolean;
}>`
  flex-shrink: 0;
  width: ${drawerWidth + 32}px;

  .MuiDrawer-paper {
    margin: 16px;
    height: calc(100vh - 32px);
    border-radius: 16px;
    padding-bottom: 16px;
    width: ${drawerWidth}px;
    background-color: ${({ isDesktop }) => (isDesktop ? 'transparent' : getRgba('#fff', 0.5))};
    box-shadow: ${({ isDesktop, theme }) => (isDesktop ? 'none' : theme.boxShadows.lg)};
    backdrop-filter: ${({ isDesktop }) => (isDesktop ? 'none' : 'saturate(200%) blur(3rem)')};
  }
`;

// ... (imports and styles remain unchanged)

export default function Sidenav() {
  const { isOpen, isDesktop, close } = useMenu(); // Destructure t from useMenu()
  const location = useLocation();
  const { selectedCompany } = useCompanyContext();
  const [isLoaded, setIsLoaded] = useState(false);

  const { t } = useTranslation(); // Using useTranslation hook

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ClientService.get();
        const owner =
          response?.data?.user?.userCompanies?.find(
            (company: any) => company.company?.id === selectedCompany?.id && company.owner === true
          ) !== undefined;

        localStorage.setItem('isOwner', JSON.stringify(owner));
        setIsLoaded(true);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };
    fetchData();
  }, [selectedCompany]); // Include selectedCompany as a dependency

  const [openIndex, setOpenIndex] = useState(-1); // State to track which submenu is open

  const handleToggle = (index: any) => {
    // Function to toggle the submenu
    setOpenIndex(prevIndex => (prevIndex === index ? -1 : index));
  };

  const isOwnerSet = localStorage.getItem('isOwner') !== null;

  if (!isOwnerSet) {
    return (
      <Side
        isDesktop={isDesktop}
        variant={isDesktop ? 'permanent' : 'temporary'}
        anchor="left"
        open={isOpen}
        onClose={close}
      >
        <Box>
          {/*<Box textAlign="center">Logo</Box>*/}
          <img src={logoBusinessApp} style={{ height: '100px' }} />
          {!isDesktop && (
            <IconButton onClick={close} size="small" sx={styles.closeMenuButton}>
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
        <Box sx={{ padding: '16px', textAlign: 'center' }}>{t('common:addCompany')}</Box>
      </Side>
    );
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Side
      isDesktop={isDesktop}
      variant={isDesktop ? 'permanent' : 'temporary'}
      anchor="left"
      open={isOpen}
      onClose={close}
    >
      <Box>
        {/*<Box textAlign="center">Logo</Box>*/}
        <img
          src={logoBusinessApp}
          style={{ height: '90px', display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}
        />
        {!isDesktop && (
          <IconButton onClick={close} size="small" sx={styles.closeMenuButton}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        )}
      </Box>

      <List>
        {links.map(({ label, icon: Icon, href, submenu }, index) => (
          <div
            key={index}
            onClick={() => {
              localStorage.setItem('sendFrom', 'false');
            }}
          >
            {submenu ? (
              <>
                <ListItem
                  disablePadding
                  button
                  style={{ margin: '5px', width: 'auto', padding: '10px', borderRadius: '10px' }}
                  onClick={() => handleToggle(index)}
                  sx={index === 2 ? styles.invoiceTab : {}}
                >
                  <ListItemIcon sx={styles.icon} className="icon">
                    <Icon color="inherit" fontSize="inherit" />
                  </ListItemIcon>
                  <ListItemText sx={styles.label}>{t(`common:${label}`)}</ListItemText>
                  {openIndex === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItem>
                <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {submenu.map((subItem, subIndex) => (
                      <ListItem key={subIndex} disablePadding>
                        <ListItemButton
                          component={NavLink}
                          to={subItem.href}
                          className={location.pathname === subItem.href ? 'active' : ''}
                          prefetch
                          sx={styles.button}
                        >
                          <ListItemIcon sx={styles.icon} className="icon">
                            <DescriptionOutlinedIcon style={{ width: '15px', height: '15px' }} />
                          </ListItemIcon>
                          <ListItemText sx={styles.label}>
                            {t(`common:${subItem.label}`)}
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </>
            ) : (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  component={NavLink}
                  to={href}
                  className={
                    location.pathname === href ||
                    (location.pathname === '/' && href === '/dashboard')
                      ? 'active'
                      : ''
                  }
                  prefetch
                  sx={index === 2 ? styles.invoiceTab : styles.button} // Apply smaller size to "Invoices" tab
                >
                  <ListItemIcon sx={styles.icon} className="icon">
                    <Icon color="inherit" fontSize="inherit" />
                  </ListItemIcon>
                  <ListItemText sx={styles.label}>{t(`common:${label}`)}</ListItemText>
                </ListItemButton>
              </ListItem>
            )}
          </div>
        ))}
      </List>
    </Side>
  );
}
