import colors from '../base/colors';
import { fontSize } from '../base/typography';
import component from '../component';

export default component('MuiInputBase', {
  defaultProps: {
    fullWidth: true,
  },

  styleOverrides: {
    root: {
      overflow: 'hidden',
      border: `1px solid ${colors.grey['300']}`,
      borderRadius: '8px',
      transition: 'box-shadow 150ms ease, border-color 150ms ease',

      // 'label + &': {
      //   marginTop: '24px !important',
      // },

      '&:before, &:after': {
        border: 'unset !important',
      },

      '&.Mui-focused': {
        borderColor: '#35d1f5',
      },

      '&.Mui-error': {
        borderColor: '#fd5c70',
      },
    },

    input: {
      fontSize: fontSize.sm,
      borderBottom: 'none',
      color: colors.grey['700'],
      padding: '8px 12px',
    },
  },
});
