import { Context, useContext } from 'react';

export default function useSafeContext<T>(context: Context<T>) {
  const value = useContext(context);

  if (typeof value === 'undefined') {
    throw new Error(
      'You are either missing a provider or trying to load a context outside its provider'
    );
  }

  return value as NonNullable<typeof value>;
}
