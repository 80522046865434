import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { useIdleTimer } from 'react-idle-timer';
import { useLayoutEffect } from 'react';

import theme from 'styles/theme';
import setInterceptor, { AuthProvider } from 'contexts/AuthContext';
import Router from 'components/Router';
import Routes from 'components/Routes';
import { MAX_IDLE_TIME } from 'config/settings';
import { RoutesProvider } from 'contexts/RoutesContext';
import { NotificationProvider } from 'contexts/NotificationContext';
import { CompanyProvider } from './contexts/CompanyContext';
import { DataProvider } from './contexts/DataContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  // forces users to download any available patches and updates
  useIdleTimer({
    timeout: MAX_IDLE_TIME,
    startOnMount: true,
    eventsThrottle: 500,
    onIdle: () => {
      console.log('Reloading due to long inactivity...');
      window.location.reload();
    },
  });

  useLayoutEffect(() => {
    setInterceptor();
  }, []);

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Router>
            <NotificationProvider>
              <AuthProvider>
                <RoutesProvider>
                  <DataProvider>
                    <CompanyProvider>
                      <Routes />
                    </CompanyProvider>
                  </DataProvider>
                </RoutesProvider>
              </AuthProvider>
            </NotificationProvider>
          </Router>
          <CssBaseline />
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </HelmetProvider>
  );
}
