import { createContext, useMemo } from 'react';

import { PRIVATE_ROUTES, PUBLIC_ROUTES, Route } from 'config/routes';
import { useAuth } from './AuthContext';
import useSafeContext from 'hooks/useSafeContext';

interface Context {
  routes: Route[];
  isAuthenticated: boolean;
}

const RoutesContext = createContext<Context | undefined>(undefined);

export function RoutesProvider({ children }: React.PropsWithChildren<unknown>) {
  const { isAuthenticated } = useAuth();
  const value = useMemo<Context>(
    () => ({
      routes: isAuthenticated ? PRIVATE_ROUTES : PUBLIC_ROUTES,
      isAuthenticated,
    }),
    [isAuthenticated]
  );

  return <RoutesContext.Provider value={value}>{children}</RoutesContext.Provider>;
}

export function useRoutes() {
  return useSafeContext(RoutesContext);
}
