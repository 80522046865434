import colors from '../base/colors';
import { fontSize } from '../base/typography';
import component from '../component';

export default component('MuiFormControlLabel', {
  styleOverrides: {
    root: {
      marginLeft: 'unset',
    },

    label: {
      display: 'inline-block',
      fontSize: fontSize.sm,
      color: colors.dark.main,
    },
  },
});
