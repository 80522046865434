import { alpha } from '@mui/material/styles';

import colors from '../base/colors';
import { fontSize, fontWeight } from '../base/typography';
import component from '../component';

export default component('MuiMenuItem', {
  styleOverrides: {
    root: {
      fontSize: fontSize.sm,
      fontWeight: fontWeight.regular,
      color: colors.dark.main,
      paddingTop: '8px',
      paddingBottom: '8px',
      borderRadius: '6px',

      '&:hover': {
        backgroundColor: alpha('#000', 0.05),
      },

      '&:active': {
        backgroundColor: alpha('#000', 0.1),
      },
    },
  },
});
