const colors = {
  background: {
    default: '#f8f9fa',
  },

  black: {
    light: '#141414',
    main: '#000',
    dark: '#000',
  },

  dark: {
    light: '#344767',
    main: '#344767',
    dark: '#344767',
  },

  info: {
    main: '#17c1e8',
    light: '#3acaeb',
    dark: '#17c1e8',
  },

  success: {
    main: '#4caf50',
    light: '#4caf50',
    dark: '#4caf50',
  },

  warning: {
    main: '#ff9800',
    light: '#ff9800',
    dark: '#ff9800',
  },

  error: {
    main: '#ef5350',
    light: '#ef5350',
    dark: '#ef5350',
  },

  secondary: {
    main: '#8392ab',
    focus: '#96a2b8',
  },

  text: {
    primary: '#67748e',
    secondary: '#67748e',
    disabled: '#67748e',
  },

  grey: {
    100: '#f8f9fa',
    200: '#e9ecef',
    300: '#dee2e6',
    400: '#ced4da',
    500: '#adb5bd',
    600: '#6c757d',
    700: '#495057',
    800: '#343a40',
    900: '#212529',
  },

  gradients: {
    primary: {
      start: '#7928ca',
      end: '#ff0080',
    },

    secondary: {
      start: '#627594',
      end: '#a8b8d8',
    },

    info: {
      start: '#2152ff',
      end: '#21d4fd',
    },

    success: {
      start: '#17ad37',
      end: '#98ec2d',
    },

    warning: {
      start: '#f53939',
      end: '#fbcf33',
    },

    error: {
      start: '#ea0606',
      end: '#ff667c',
    },

    light: {
      start: '#ced4da',
      end: '#ebeff4',
    },

    dark: {
      start: '#141727',
      end: '#3a416f',
    },
  },
};

export default colors;
