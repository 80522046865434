import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { createStyles } from 'utils/styles';

const date = new Date();

const styles = createStyles({
  container: {
    textAlign: 'center',
    py: 5,
  },

  copy: theme => ({
    fontSize: theme.typography.pxToRem(16),
  }),
});

export default function Footer() {
  return (
    <Box sx={styles.container}>
      <Typography color="secondary" sx={styles.copy}>
        Copyright &copy; {date.getFullYear()}
      </Typography>
    </Box>
  );
}
