import boxShadows from '../base/boxShadows';
import colors from '../base/colors';
import { fontWeight } from '../base/typography';
import component from '../component';

export default component('MuiListItemButton', {
  styleOverrides: {
    root: {
      borderRadius: '8px',
      fontSize: 14,
      color: colors.dark.main,

      '&.Mui-selected, &.active': {
        fontWeight: fontWeight.medium,
        boxShadow: boxShadows.xxl,
        '&, &:hover': {
          backgroundColor: '#fff',
        },
      },
    },
  },
});
