/* eslint-disable */
import { createContext, useCallback, useEffect, useMemo } from 'react';
import { hashQueryKey, useQuery, useQueryClient } from 'react-query';
import { AxiosError, AxiosRequestConfig } from 'axios';

import useSafeContext from 'hooks/useSafeContext';
import PageLoader from 'components/PageLoader';
import AuthService, { User } from 'services/AuthService';
import { client, RequestConfig } from 'services/ApiService';

interface Context {
  user?: User;
  isAuthenticated: boolean;
  logout: () => void;
}

let ref: Promise<void> | undefined;
let requestInterceptor: number | null = null;
export default function setInterceptor() {
  if (requestInterceptor !== null) {
    return;
  }
  requestInterceptor = client.interceptors.request.use(
    config => {
      const accessToken = window.localStorage.getItem('accessToken');
      if (accessToken) {
        // config.headers = {
        //   ...config.headers,
        //   Authorization: `Bearer ${accessToken}`,
        // };
        config.headers['Authorization'] = `Bearer ${accessToken}`; //todo fix
      }

      return config;
    },
    error => {
      console.log('error interceptor', error);
      Promise.reject(error);
    }
  );
}

async function refresh(callback: () => void) {
  if (ref) {
    ref = ref.then(callback);
    return ref;
  }

  ref = AuthService.refreshToken()
    .then(callback)
    .finally(() => {
      ref = undefined;
    });

  return ref;
}

const AuthContext = createContext<Context | undefined>(undefined);

export class MissingTokenException extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'MissingTokenException';
  }
}

export function AuthProvider({ children }: React.PropsWithChildren<unknown>) {
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ['user', 'me'],
    async () => {
      try {
        const { data } = await AuthService.me();
        return data.user;
      } catch (error) {
        if (error instanceof MissingTokenException) {
          return undefined;
        }
        // @ts-ignore
        if (error?.response?.status == 400) {
          value.isAuthenticated = true;
        }
        // if we came this far rethrow and let the retry process handle the rest
        throw error;
      }
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      notifyOnChangeProps: ['isLoading', 'data'],
      retry: (failureCount, error: AxiosError) => {
        const hasFailed = error.isAxiosError && error.response?.status === 401;
        const shouldRetry = hasFailed && failureCount < 3;

        return shouldRetry;
      },
    }
  );
  const value = useMemo<Context>(
    () => ({
      isAuthenticated: !!data?.id,
      user: data,
      logout: () => {
        logout();
      },
    }),
    [data, localStorage.getItem('accessToken')]
  );
  useEffect(() => {
    //   requestInterceptor = client.interceptors.request.use(
    //   config => {
    //
    //       console.log('requestinterceptor')
    //     const accessToken = window.localStorage.getItem('accessToken');
    //       console.log('reqint', accessToken)
    //     if (accessToken) {
    //       config.headers = {
    //         ...config.headers,
    //         Authorization: `Bearer ${accessToken}`,
    //       };
    //     }
    //
    //     return config;
    //   },
    //   error => {
    //       console.log('error interceptor', error);
    //       Promise.reject(error);
    //   }
    // );

    const responseInterceptor = client.interceptors.response.use(
      response => response,
      async (error: AxiosError) => {
        const status = error.response?.status;
        const isRefreshable = (error.response?.config as RequestConfig)?.refreshable ?? true;

        if (status !== 401 || !isRefreshable) {
          return Promise.reject(error);
        }

        try {
          const response = await refresh(() => {
            return client.request(error.config as AxiosRequestConfig);
          });

          return response;
        } catch {
          const keyHash = hashQueryKey(['user', 'me']);

          queryClient.setQueryData(['user', 'me'], undefined);
          queryClient.removeQueries({ predicate: ({ queryHash }) => queryHash !== keyHash });

          return Promise.reject(error);
        }
      }
    );

    return () => {
      // if (requestInterceptor !== null) {
      //     client.interceptors.request.eject(requestInterceptor);
      //     requestInterceptor = null;
      // }
      client.interceptors.response.eject(responseInterceptor);
    };
  }, [queryClient, data]);

  const logout = useCallback(() => {
    localStorage.clear();
    // localStorage.removeItem('accessToken');
    // localStorage.removeItem('refreshToken');
    // localStorage.removeItem('selectedCompany');
    // localStorage.removeItem('selectedCompanyId');
    queryClient.setQueryData(['user', 'me'], undefined); // Update the user data to undefined
    // Set isAuthenticated to false
    value.isAuthenticated = false;
    // Redirect to the login form
  }, [queryClient]);

  if (isLoading) {
    return <PageLoader />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useSafeContext(AuthContext);
}
