import Container from './Container';
import Content from './Content';
import Footer from './Footer';
import Header from './Header';
import Hero from './Hero';
import Main from './Main';

export default function PublicLayout({ children }: React.PropsWithChildren<unknown>) {
  return (
    <Container>
      <Main>
        <Hero>
          <Header />
        </Hero>
        <Content>{children}</Content>
      </Main>
      <Footer />
    </Container>
  );
}
