import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import * as React from 'react';

import { createStyles, getRgba } from 'utils/styles';
import { useMenu } from 'contexts/MenuContext';
import UserMenu from './UserMenu';
import LanguageMenu from '../LanguageSelect';

const styles = createStyles({
  item: theme => ({
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.dark.main,
  }),

  home: {
    display: 'flex',
    justifyContent: 'center',
    opacity: 0.5,
    fontSize: 16,
  },

  menu: {
    mr: 2,
  },

  section: {
    display: 'flex',
    alignItems: 'center',
  },
  navItem: theme => ({
    p: 1,
    mx: 1,
    color: theme.palette.dark.main,
  }),

  profile: theme => ({
    borderRadius: '20px',
    color: theme.palette.dark.main,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'medium',
    px: 1,
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    '&:hover': {
      backgroundColor: getRgba('#000', 0.04),
    },
  }),

  lang: theme => ({
    fontSize: theme.typography.pxToRem(20),
  }),
});

export default function Header() {
  const { isOpen, isDesktop, toggle } = useMenu();

  return (
    <AppBar position="static">
      <Toolbar>
        <Box flexGrow={1} sx={styles.section}>
          {!isDesktop && (
            <IconButton onClick={toggle} sx={styles.menu}>
              {isOpen ? <MenuOpenRoundedIcon /> : <MenuRoundedIcon />}
            </IconButton>
          )}
          {/*<Box>*/}
          {/*  <Box>*/}
          {/*    <Breadcrumbs>*/}
          {/*      <Anchor component={Link} to="/">*/}
          {/*        <Typography component="span" sx={[styles.item, styles.home]}>*/}
          {/*          <Home fontSize="inherit" />*/}
          {/*        </Typography>*/}
          {/*      </Anchor>*/}
          {/*      <Typography sx={styles.item}>Dashboard</Typography>*/}
          {/*    </Breadcrumbs>*/}
          {/*  </Box>*/}
          {/*  <Typography variant="h6" fontWeight="bold">*/}
          {/*    Dashboard*/}
          {/*  </Typography>*/}
          {/*</Box>*/}
        </Box>
        <Box sx={styles.section}>
          <UserMenu />
        </Box>
        <LanguageMenu sx={[styles.navItem, styles.lang]} />
      </Toolbar>
    </AppBar>
  );
}
