import { BASE_FONT_SIZE } from 'config/settings';
import component from '../component';

export default component('MuiCssBaseline', {
  styleOverrides: {
    html: {
      fontSize: BASE_FONT_SIZE,
    },
  },
});
