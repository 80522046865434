import component from '../component';

export default component('MuiAppBar', {
  defaultProps: {
    elevation: 0,
  },

  styleOverrides: {
    root: {
      backgroundColor: 'transparent',
    },
  },
});
