import * as i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export const languages = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'mk',
    label: 'Македонски',
  },
  {
    value: 'al',
    label: 'Shqip',
  },
];

const namespaces = ['common', 'auth', 'error', 'profile', 'client', 'invoice', 'bank', 'company'];

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    detection: {
      lookupCookie: 'locale',
      caches: ['cookie'],
      cookieMinutes: 60 * 24 * 365,
    },
    // TODO: maybe lazy load translation files in the future
    resources: Object.fromEntries(
      languages.map(({ value }) => [
        value,
        Object.fromEntries(
          namespaces.map(namespace => [
            namespace,
            require(`../public/locales/${value}/${namespace}`),
          ])
        ),
      ])
    ),
  });

export default i18n;
