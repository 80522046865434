import Box from '@mui/material/Box';

import { createStyles } from 'utils/styles';

const styles = createStyles({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  mt: '-320px',
});

export default function Content({ children }: React.PropsWithChildren<unknown>) {
  return <Box sx={styles}>{children}</Box>;
}
