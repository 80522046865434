import { pxToRem } from 'utils/styles';
import colors from '../base/colors';
import component from '../component';

export default component('MuiTableCell', {
  styleOverrides: {
    root: {
      borderColor: colors.grey[300],
      whiteSpace: 'nowrap',
    },

    head: {
      fontSize: pxToRem(11),
      color: colors.secondary.main,
      textTransform: 'uppercase',
    },
  },
});
