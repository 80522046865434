import Menu from '@mui/material/Menu';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import TranslateRoundedIcon from '@mui/icons-material/TranslateRounded';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { languages } from 'i18n';

export default function LanguageMenu(props: Omit<IconButtonProps, 'onClick'>) {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const { i18n, t } = useTranslation();
  const isOpen = Boolean(anchor);

  function closeMenu() {
    setAnchor(null);
  }

  function changeLanguage(value: string) {
    i18n.changeLanguage(value).catch(console.log);
    closeMenu();
  }

  return (
    <>
      <IconButton
        {...props}
        onClick={e => setAnchor(e.currentTarget)}
        aria-label={t('common:changeLanguage')}
      >
        <TranslateRoundedIcon fontSize="inherit" color="inherit" />
      </IconButton>
      <Menu
        anchorEl={anchor}
        open={isOpen}
        aria-controls={isOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {languages.map(({ value, label }) => (
          <MenuItem
            key={value}
            selected={i18n.language.startsWith(value)}
            onClick={() => changeLanguage(value)}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
