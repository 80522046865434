/* eslint-disable */
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import logoFitr from '../../images/FITR-MKD-Full-White-1980x715-1-1024x370-1.png';

function Footer() {
  return (
    <AppBar position="static" sx={{ top: 'auto', bottom: 0 }}>
      <Toolbar disableGutters>
        <img src={logoFitr} alt="Logo" style={{ height: '100px', marginRight: '10px' }} />
      </Toolbar>
    </AppBar>
  );
}

export default Footer;
