import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';

export default function Router({ children }: React.PropsWithChildren<unknown>) {
  return (
    <BrowserRouter>
      <ErrorBoundary fallback={<h1>Something went wrong...</h1>}>{children}</ErrorBoundary>
    </BrowserRouter>
  );
}
