import component from '../component';

export default component('MuiDrawer', {
  defaultProps: {
    hideBackdrop: true,
    elevation: 0,
  },

  styleOverrides: {
    root: {
      '& .MuiDrawer-paper': {
        border: 'none',
      },
    },
  },
});
