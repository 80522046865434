import { createBoxShadow } from 'utils/styles';
import colors from './colors';

const { black } = colors;

const boxShadows = {
  xs: createBoxShadow([0, 2], [9, -5], black.main, 0.15),
  sm: createBoxShadow([0, 5], [10, 0], black.main, 0.12),
  md: `${createBoxShadow([0, 4], [6, -1], black.light, 0.12)}, ${createBoxShadow(
    [0, 2],
    [4, -1],
    black.light,
    0.07
  )}`,
  lg: `${createBoxShadow([0, 8], [26, -4], black.light, 0.15)}, ${createBoxShadow(
    [0, 8],
    [9, -5],
    black.light,
    0.06
  )}`,
  xl: createBoxShadow([0, 23], [45, -11], black.light, 0.25),
  xxl: createBoxShadow([0, 20], [27, 0], black.main, 0.05),
};

export default boxShadows;
