import { getRgba } from 'utils/styles';
import component from '../component';

export default component('MuiDivider', {
  styleOverrides: {
    root: {
      borderColor: getRgba('#000', 0.06),
    },
  },
});
