import { forwardRef } from 'react';
import { Link as BaseLink, LinkProps } from 'react-router-dom';

import usePrefetch from 'hooks/usePrefetch';

interface Props extends LinkProps {
  prefetch?: boolean;
}

const Link = forwardRef<HTMLAnchorElement, Props>(({ prefetch = false, ...props }, ref) => {
  const { to } = props;

  usePrefetch({ enabled: prefetch, to });

  return <BaseLink {...props} ref={ref} />;
});

export default Link;
