import { createLinearGradient } from 'utils/styles';
import colors from '../base/colors';
import component from '../component';

export default component('MuiCheckbox', {
  styleOverrides: {
    root: {
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: 20,
      height: 20,
      marginRight: 6,
      padding: 0,
      color: 'red',
      border: `1px solid ${colors.grey[300]}`,
      borderRadius: 6,
      transition: 'all 250ms ease',

      '&:hover': {
        backgroundColor: 'transparent',
      },

      '& .MuiSvgIcon-root': {
        fill: 'transparent',
      },

      '&.Mui-focusVisible': {
        border: `1px solid ${colors.info.main} !important`,
      },
    },

    colorPrimary: {
      backgroundColor: 'transparent',

      '&.Mui-checked': {
        borderColor: colors.dark.main,
        backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${createLinearGradient(
          colors.gradients.dark.start,
          colors.gradients.dark.end
        )}`,
      },

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    colorSecondary: {
      backgroundColor: 'transparent',

      '&.Mui-checked': {
        borderColor: colors.dark.main,
        backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"), ${createLinearGradient(
          colors.gradients.dark.start,
          colors.gradients.dark.end
        )}`,
      },

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
});
