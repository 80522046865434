import colors from '../base/colors';
import component from '../component';

export default component('MuiCircularProgress', {
  styleOverrides: {
    root: {
      color: colors.dark.main,
    },
  },
});
