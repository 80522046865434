/* eslint-disable */
import axios, { AxiosRequestConfig } from 'axios';

export interface RequestConfig extends AxiosRequestConfig {
  refreshable?: boolean;
}

const PORT = process.env.REACT_APP_API_URL;

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

const ApiService = {
  get<R>(url: string, config?: RequestConfig) {
    return client.get<R>(url, config);
  },

  post<R>(url: string, data?: any, config?: RequestConfig) {
    return client.post<R>(url, data, config);
  },

  put<R>(url: string, data?: any, config?: RequestConfig) {
    return client.put<R>(url, data, config);
  },

  delete<R>(url: string, config?: RequestConfig) {
    return client.delete<R>(url, config);
  },
};

export default ApiService;
