/* eslint-disable */
import React, { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'contexts/AuthContext';
import { createStyles } from 'utils/styles';
import Link from 'components/Link';
import MenuItem from '@mui/material/MenuItem';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import Drawer from '@mui/material/Drawer';
import { useCompanyContext } from '../../contexts/CompanyContext';
import CloseIcon from '@mui/icons-material/Close';
import { useQuery } from 'react-query';
import ClientService from '../../services/ClientService';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import { useQueryClient } from 'react-query';
import Badge from '@mui/material/Badge';

const styles = createStyles({
  menu: {
    width: '200px',
  },
  username: theme => ({
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'medium',
  }),
  view: theme => ({
    fontSize: theme.typography.pxToRem(12),
    mt: '2px',
  }),
  icon: {
    mr: '5px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
    height: '38px',
  },
  menuItem: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '200px',
  },
  accepted: {
    color: 'green',
    float: 'right',
  },

  rejected: {
    color: 'red',
    float: 'right',
  },
});

export default function UserMenu() {
  const queryClient = useQueryClient();
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { logout } = useAuth();
  const { t } = useTranslation();
  const [isBusinessMenuOpen, setIsBusinessMenuOpen] = useState<null | HTMLElement>(null);
  const { selectedCompany, selectedCompanyId, setSelectedCompanyId, setSelectedCompany } =
    useCompanyContext();
  const [notificationsData, setNotificationsData] = useState<any>([]);
  const [pendingConnectionCount, setPendingConnectionCount] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  function handleClose() {
    setAnchor(null);
    setIsBusinessMenuOpen(null);
  }
  const sections = [
    [
      {
        icon: CorporateFareRoundedIcon,
        label: t('common:Profile'),
        href: '/settings/organization-profile',
        onClick: () => {
          handleClose();
        },
      },
    ],
    [
      {
        icon: LogoutRoundedIcon,
        label: t('common:signOut'),
        onClick: () => {
          queryClient.removeQueries(['companies']);
          logout();
        },
      },
    ],
  ];

  const handleNotificationsOpen = () => {
    setIsDrawerOpen(true);
    setIsNotificationsOpen(true);
    setIsModalOpen(true);
  };

  const handleNotificationsClose = () => {
    setIsNotificationsOpen(false);
    setIsModalOpen(false);
  };

  const { data, isLoading } = useQuery(
    ['notifications', 'companies', selectedCompanyId],
    () =>
      selectedCompanyId
        ? // @ts-ignore
          ClientService.getNotifications(selectedCompanyId).then(({ data }) => data.data.content)
        : [],
    {
      onSuccess: data => {
        const pendingCount = data.reduce((count: number, notification: any) => {
          return notification.connectionStatus === 'PENDING' ? count + 1 : count;
        }, 0);

        setPendingConnectionCount(pendingCount);
        setNotificationsData(data);
      },
    }
  );

  const handleAccept = useCallback(
    async (notification: any) => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.post<any>(
          `https://api.businessapp.mk/api/companies/${selectedCompanyId}/connections/${notification.id}/`,
          {
            id: `${notification.id}`,
            connectionStatus: 'ACCEPTED',
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Update the notificationsData state variable with the modified data
        const updatedNotifications = notificationsData.map((item: any) => {
          if (item.id === notification.id) {
            return {
              ...item,
              connectionStatus: 'ACCEPTED',
            };
          }
          return item;
        });

        // Update the state variable with the modified notifications
        setNotificationsData(updatedNotifications);
      } catch (error) {
        console.error('Error accepting notifications:', error);
      }
    },
    [notificationsData]
  );

  // Function to handle the "Reject" action
  const handleReject = useCallback(
    async (notification: any) => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.post<any>(
          `https://api.businessapp.mk/api/companies/${selectedCompanyId}/connections/${notification.id}/`,
          {
            id: `${notification.id}`,
            connectionStatus: 'REJECTED',
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Update the notificationsData state variable with the modified data
        const updatedNotifications = notificationsData.map((item: any) => {
          if (item.id === notification.id) {
            return {
              ...item,
              connectionStatus: 'REJECTED',
            };
          }
          return item;
        });

        // Update the state variable with the modified notifications
        setNotificationsData(updatedNotifications);
      } catch (error) {
        console.error('Error rejecting notifications:', error);
      }
    },
    [notificationsData]
  );

  return (
    <>
      <Menu
        anchorEl={isBusinessMenuOpen}
        open={Boolean(isBusinessMenuOpen)}
        onClose={handleClose}
        PaperProps={{ sx: styles.menu }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          component={Link}
          to="/companies"
          style={{
            textDecoration: 'none',
            color: '#344767',
            fontSize: '13px',
            marginTop: '2px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={handleClose}
        >
          <SettingsRoundedIcon fontSize="small" style={{ marginRight: '0.5rem' }} />
          {t('common:manage')}
        </MenuItem>
      </Menu>
      <IconButton
        onClick={e => setIsBusinessMenuOpen(e.currentTarget)}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
            cursor: 'pointer',
          },
        }}
      >
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '200px',
            textAlign: 'right',
            color: '#344767',
          }}
        >
          {selectedCompany?.displayName ? selectedCompany?.displayName : selectedCompany?.name}
        </Typography>
        <BusinessRoundedIcon />
      </IconButton>
      {/* Bell icon with notifications drawer */}
      {!isDrawerOpen ? (
        <Badge
          color="error"
          badgeContent={pendingConnectionCount > 0 ? pendingConnectionCount : 0}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <IconButton sx={{ padding: '4px' }} onClick={handleNotificationsOpen}>
            <NotificationsIcon />
          </IconButton>
        </Badge>
      ) : (
        <IconButton onClick={handleNotificationsOpen}>
          <NotificationsIcon />
        </IconButton>
      )}
      <IconButton onClick={e => setAnchor(e.currentTarget)}>
        <AccountCircleTwoToneIcon />
      </IconButton>
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleClose}
        PaperProps={{ sx: styles.menu }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/*<MenuItem>*/}
        {/*    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>*/}
        {/*        <Typography component="div" style={{ fontSize: '16px' }}>*/}
        {/*            {user?.username}*/}
        {/*        </Typography>*/}
        {/*        /!*<Typography*!/*/}
        {/*        /!*    component="div"*!/*/}
        {/*        /!*    color="secondary"*!/*/}
        {/*        /!*    onClick={() => {*!/*/}
        {/*        /!*        navigate('/profile');*!/*/}
        {/*        /!*        handleClose();*!/*/}
        {/*        /!*    }}*!/*/}
        {/*        /!*    sx={{*!/*/}
        {/*        /!*        cursor: 'pointer',*!/*/}
        {/*        /!*        color: 'black',*!/*/}
        {/*        /!*        fontSize: '13px',*!/*/}
        {/*        /!*        marginLeft: '4rem',*!/*/}
        {/*        /!*        alignItems: 'center',*!/*/}
        {/*        /!*        display: 'flex',*!/*/}
        {/*        /!*    }}*!/*/}
        {/*        /!*>*!/*/}
        {/*        /!*    <SettingsRoundedIcon fontSize="small" style={{ marginRight: '0.5rem' }} />*!/*/}
        {/*        /!*    Manage*!/*/}
        {/*        /!*</Typography>*!/*/}
        {/*    </Box>*/}
        {/*</MenuItem>*/}
        <Divider />
        {sections.map((section, i) => [
          section.map(({ icon: Icon, label, ...props }, j) => (
            <MenuItem
              key={`item-${i}-${j}`}
              {...('href' in props
                ? { component: Link, to: props.href, prefetch: true }
                : { onClick: props.onClick })}
            >
              <Box component="span" sx={styles.icon}>
                <Icon color="inherit" fontSize="inherit" />
              </Box>
              {label}
            </MenuItem>
          )),
          i < sections.length - 1 && <Divider key={`divider-${i}`} />,
        ])}
      </Menu>
      {/* Notifications Drawer */}
      <Drawer
        anchor="right"
        open={isNotificationsOpen}
        onClose={handleNotificationsClose}
        BackdropProps={{ invisible: true }}
        ModalProps={{ onClick: handleNotificationsClose }}
      >
        {/* Attach an onClick handler to the container that wraps the content */}
        <div
          style={{ width: '400px', padding: '16px', height: '100%' }}
          onClick={e => e.stopPropagation()}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
            }}
            onClick={handleNotificationsClose}
            color="primary"
          >
            <CloseIcon />
          </IconButton>
          {/* Add your notifications content here */}
          <Typography variant="h6" gutterBottom>
            {t('common:notifications')}
          </Typography>
          {notificationsData.length === 0 ? (
            <Typography variant="body2">{t('common:noConnections')}</Typography>
          ) : (
            notificationsData.map((notification: any) => (
              <div key={notification?.id} style={{ marginTop: '4rem' }}>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ width: '80%', display: 'block', alignItems: 'center' }}>
                    {notification?.senderCompany?.id === selectedCompanyId ? (
                      <Typography variant="body1">
                        {' '}
                        {t('common:connectionRequestSendTo')}:
                      </Typography>
                    ) : (
                      <Typography variant="body1">
                        {' '}
                        {t('common:connectionRequestReceivedFrom')}:
                      </Typography>
                    )}
                    <Typography
                      variant="body1"
                      style={{ marginTop: '15px', fontWeight: '600', color: '#000' }}
                    >
                      {notification?.receiverEmail}
                    </Typography>
                    <Typography variant="body2" style={{ marginTop: '5px' }}>
                      {t('common:senderCompany')}: {notification?.senderCompany?.displayName}
                    </Typography>
                  </div>

                  <div
                    style={{
                      width: '20%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {notification?.connectionStatus === 'PENDING' &&
                    selectedCompanyId !== notification.senderCompany.id ? (
                      <div style={{ display: 'flex', marginLeft: 'auto' }}>
                        <IconButton
                          onClick={() => handleAccept(notification)}
                          color="success"
                          size="small"
                          aria-label="Accept"
                        >
                          <CheckIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleReject(notification)}
                          color="error"
                          size="small"
                          aria-label="Reject"
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    ) : (
                      <Typography
                        variant="body2"
                        sx={
                          notification?.connectionStatus === 'ACCEPTED'
                            ? {
                                ...styles.accepted,
                                backgroundColor: '#CCE8CD',
                                color: '#65BB6A',
                                padding: '8px',
                                fontWeight: '600',
                                fontSize: '13px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                              }
                            : notification?.connectionStatus === 'REJECTED'
                            ? {
                                ...styles.rejected,
                                backgroundColor: '#F4C7C7',
                                color: '#F44336',
                                padding: '8px',
                                fontWeight: '600',
                                fontSize: '13px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                              }
                            : {}
                        }
                      >
                        {notification?.connectionStatus}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </Drawer>
      ;{/* Modal-like Background */}
      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
          }}
          onClick={handleNotificationsClose}
        />
      )}
    </>
  );
}
