import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createContext, useEffect, useMemo, useState } from 'react';

import useSafeContext from 'hooks/useSafeContext';

interface Context {
  isOpen: boolean;
  isDesktop: boolean;
  close(): void;
  toggle(): void;
}

const MenuContext = createContext<Context | undefined>(undefined);

export function MenuProvider({ children }: React.PropsWithChildren<unknown>) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (!matches) {
      setOpen(false);
    }
  }, [matches]);

  const value = useMemo<Context>(
    () => ({
      isOpen: isOpen || matches,
      isDesktop: matches,
      toggle: () => {
        if (matches) {
          return;
        }

        setOpen(!isOpen);
      },
      close: () => {
        if (matches) {
          return;
        }

        setOpen(false);
      },
    }),
    [isOpen, matches]
  );

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
}

export function useMenu() {
  return useSafeContext(MenuContext);
}
