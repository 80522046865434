import type { SxProps, Theme } from '@mui/material';
import chroma from 'chroma-js';

import { BASE_FONT_SIZE } from 'config/settings';

export function pxToRem(value: number, base = BASE_FONT_SIZE) {
  return `${value / base}rem`;
}

export function hexToRgb(color: string) {
  return chroma(color).rgb().join(', ');
}

export function getRgba(color: string, opacity: number) {
  return `rgba(${hexToRgb(color)}, ${opacity})`;
}

export function createBoxShadow(
  offset: [number, number],
  radius: [number, number],
  color: string,
  opacity: number,
  inset?: string
) {
  const [x, y] = offset;
  const [blur, spread] = radius;

  const shadow = `${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(spread)} ${getRgba(
    color,
    opacity
  )}`;

  return [inset, shadow].filter(Boolean).join(' ');
}

export function createLinearGradient(start: string, end: string, angle = 310) {
  return `linear-gradient(${angle}deg, ${start}, ${end})`;
}

export function createStyles<T extends SxProps<Theme>>(styles: T) {
  return styles;
}
