/* eslint-disable */
import { lazy, Suspense, useMemo, useEffect } from 'react';
import { Navigate, Route, Routes as Switch, useLocation, useNavigate } from 'react-router-dom';

import { Route as RouteConfig } from 'config/routes';
import PrivateLayout from './PrivateLayout';
import PublicLayout from './PublicLayout';
import { useRoutes } from 'contexts/RoutesContext';
import NotFound from 'routes/404';
import PageLoader from './PageLoader';
import { createStyles } from 'utils/styles';
import { useCompanyContext } from '../contexts/CompanyContext';

function getLazyRoutes(routes: RouteConfig[]) {
  return routes.map(({ path, route, index }) => {
    const Component = lazy(() => import(`routes/${route}`));

    return <Route key={path} path={path} index={index} element={<Component />} />;
  });
}

const styles = createStyles({
  loader: {
    py: [3, 7],
    minHeight: 'auto',
  },
});

export default function Routes() {
  const { isAuthenticated, routes } = useRoutes();
  const { setSelectedCompany, setSelectedCompanyId } = useCompanyContext();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams?.get('token');
  useEffect(() => {
    if (window.location.pathname.includes('email-confirmation')) {
      if (token) {
        localStorage.clear();
        setSelectedCompany(null);
        setSelectedCompanyId(null);
        navigate(`/email-confirmation?token=${token}`);
      }
    }
  }, []);

  const children = useMemo(() => {
    const lazyRoutes = getLazyRoutes(routes);

    if (isAuthenticated) {
      return (
        <PrivateLayout>
          <Suspense fallback={<PageLoader sx={styles.loader} />}>
            <Switch>
              {lazyRoutes}
              <Route path="*" element={<NotFound />} />
            </Switch>
          </Suspense>
        </PrivateLayout>
      );
    }

    return (
      <PublicLayout>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            {lazyRoutes}
            <Route path="*" element={<Navigate to="/" />} />
          </Switch>
        </Suspense>
      </PublicLayout>
    );
  }, [isAuthenticated, routes]);

  return children;
}
