/* eslint-disable */
import ApiService from './ApiService';
import ClientService from './ClientService';

export enum Role {
  Admin = 'ROLE_ADMIN',
  Moderator = 'ROLE_MODERATOR',
  Client = 'ROLE_CLIENT',
  Accountant = 'ROLE_ACCOUNTANT',
  Client_Accountant = 'ROLE_CLIENT_ACCOUNTANT',
}

export interface User {
  password?: string;
  id: string | null;
  username: string;
  email: string | null;
  roles: Role[];
  userCompanies: any[];
}

interface TokenResponse {
  tokenType: string;
  accessToken: string;
  refreshToken: string;
}

export class MissingTokenException extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'MissingTokenException';
  }
}

const AuthService = {
  me: () => {
    const hasToken = !!localStorage.getItem('accessToken');

    if (!hasToken) {
      throw new MissingTokenException();
    }

    return ClientService.get();
  },

  signUp(data: {
    username: string;
    email: string;
    password: string;
    role: Role;
    companyId?: string;
  }) {
    return ApiService.post('/auth/signup', data, { refreshable: false });
  },

  forgetPassword(data: { email: string }) {
    return ApiService.post('/auth/send_password_reset/', data, { refreshable: true });
  },

  resetPassword(data: { token: string; password: string; repeatPassword: string }) {
    return ApiService.post('/auth/password_reset/', data);
  },

  async signIn(data: { username: string; password: string }) {
    const response = await ApiService.post<User & TokenResponse>('/auth/signin', data, {
      refreshable: true,
    });

    const { accessToken, refreshToken, tokenType, ...rest } = response.data;

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);

    return rest as User;
  },

  signOut(userId: string) {
    localStorage.clear();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    return ApiService.post('/auth/signout', { userId });
  },

  async refreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) {
      throw new MissingTokenException();
    }

    const { data } = await ApiService.post<{ accessToken: string }>(
      '/auth/refreshtoken',
      { refreshToken },
      { refreshable: false }
    );

    window.localStorage.setItem('accessToken', data.accessToken);
  },
};

export default AuthService;
