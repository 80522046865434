import Box from '@mui/material/Box';

import { createStyles } from 'utils/styles';

const styles = createStyles({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  px: 2,
  pt: 2,
});

export default function Main({ children }: React.PropsWithChildren<unknown>) {
  return <Box sx={styles}>{children}</Box>;
}
