import React, { createContext, useState, useContext, useEffect } from 'react';

interface CompanyContextType {
  selectedCompanyId: any;
  selectedCompany: any; // Add selectedCompany property
  editCompany: any; // Add selectedCompany property
  setSelectedCompanyId: (companyId: any) => void;
  setSelectedCompany: (company: any) => void; // Add setSelectedCompany function
  setEditCompany: (company: any) => void; // Add setSelectedCompany function
}

const CompanyContext = createContext<CompanyContextType | undefined>(undefined);

export function useCompanyContext(): CompanyContextType {
  const context = useContext(CompanyContext);
  if (!context) {
    throw new Error('useCompanyContext must be used within a CompanyProvider');
  }
  return context;
}

interface CompanyProviderProps {
  children: React.ReactNode;
}

export function CompanyProvider({ children }: CompanyProviderProps) {
  const [selectedCompanyId, setSelectedCompanyId] = useState<any>(() => {
    const storedData = localStorage.getItem('selectedCompanyId');
    return storedData ? JSON.parse(storedData) : null;
  });
  const [selectedCompany, setSelectedCompany] = useState<any>(() => {
    const storedData = localStorage.getItem('selectedCompany');
    return storedData ? JSON.parse(storedData) : null;
  });
  const [editCompany, setEditCompany] = useState<any>(null);

  useEffect(() => {
    // Update localStorage when selectedCompany changes
    localStorage.setItem('selectedCompany', JSON.stringify(selectedCompany));
    localStorage.setItem('selectedCompanyId', JSON.stringify(selectedCompanyId));
  }, [selectedCompany, selectedCompanyId]);

  const value: CompanyContextType = {
    selectedCompanyId,
    selectedCompany,
    editCompany,
    setSelectedCompanyId,
    setSelectedCompany,
    setEditCompany,
  };

  return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>;
}
