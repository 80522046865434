import colors from '../base/colors';
import { fontSize } from '../base/typography';
import component from '../component';

export default component('MuiBreadcrumbs', {
  styleOverrides: {
    li: {
      lineHeight: 0,
    },

    separator: {
      fontSize: fontSize.sm,
      color: colors.grey[600],
    },
  },
});
