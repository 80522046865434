import component from '../component';

export default component('MuiTableRow', {
  styleOverrides: {
    root: {
      '&:last-child td': {
        border: 'none',
      },
    },
  },
});
