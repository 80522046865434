import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

import { pxToRem } from 'utils/styles';
import boxShadows from '../base/boxShadows';
import colors from '../base/colors';
import { fontWeight } from '../base/typography';
import component from '../component';

export default component('MuiAlert', {
  styleOverrides: {
    root: {
      borderRadius: '8px',
      paddingLeft: '21.5px',
      position: 'relative',
      backgroundColor: '#fff',
      boxShadow: boxShadows.md,
      alignItems: 'center',
      color: colors.dark.main,
      fontWeight: fontWeight.medium,
      postion: 'relative',
      fontSize: pxToRem(14),

      '&::after': {
        position: 'absolute',
        content: '""',
        left: '7.5px',
        height: 'calc(100% - 15px)',
        width: '3px',
        borderRadius: '10px',
      },
    },

    action: {
      paddingTop: 0,
    },

    standardError: {
      '&::after': {
        backgroundColor: colors.error.main,
      },
    },

    standardSuccess: {
      '&::after': {
        backgroundColor: colors.success.main,
      },
    },

    standardInfo: {
      '&::after': {
        backgroundColor: colors.info.main,
      },
    },

    standardWarning: {
      '&::after': {
        backgroundColor: colors.warning.main,
      },
    },
  },

  defaultProps: {
    iconMapping: {
      warning: <WarningTwoToneIcon color="inherit" />,
      success: <CheckCircleTwoToneIcon color="inherit" />,
      info: <InfoTwoToneIcon color="inherit" />,
      error: <ErrorTwoToneIcon color="inherit" />,
    },
  },
});
