import { createTheme } from '@mui/material';

import boxShadows from './base/boxShadows';
import breakpoints from './base/breakpoints';
import colors from './base/colors';
import filters from './base/filters';
import typography from './base/typography';
import Alert from './components/Alert';
import AppBar from './components/AppBar';
import Breadcrumbs from './components/Breadcrumbs';
import Button from './components/Button';
import ButtonBase from './components/ButtonBase';
import Checkbox from './components/Checkbox';
import CircularProgress from './components/CircularProgress';
import CssBaseline from './components/CssBaseline';
import Divider from './components/Divider';
import Drawer from './components/Drawer';
import FormControl from './components/FormControl';
import FormControlLabel from './components/FormControlLabel';
import FormLabel from './components/FormLabel';
import InputBase from './components/InputBase';
import InputLabel from './components/InputLabel';
import Link from './components/Link';
import ListItemButton from './components/ListItemButton';
import ListItemIcon from './components/ListItemIcon';
import ListItemText from './components/ListItemText';
import Menu from './components/Menu';
import MenuItem from './components/MenuItem';
import TableCell from './components/TableCell';
import TableRow from './components/TableRow';
import Toolbar from './components/Toolbar';

const theme = createTheme({
  palette: colors,
  boxShadows,
  breakpoints,
  typography,
  filters,
  components: {
    MuiCssBaseline: CssBaseline,
    MuiButtonBase: ButtonBase,
    MuiDrawer: Drawer,
    MuiListItemButton: ListItemButton,
    MuiListItemIcon: ListItemIcon,
    MuiListItemText: ListItemText,
    MuiAppBar: AppBar,
    MuiToolbar: Toolbar,
    MuiBreadcrumbs: Breadcrumbs,
    MuiLink: Link,
    MuiFormLabel: FormLabel,
    MuiInputBase: InputBase,
    MuiFormControl: FormControl,
    MuiInputLabel: InputLabel,
    MuiButton: Button,
    MuiCircularProgress: CircularProgress,
    MuiMenu: Menu,
    MuiMenuItem: MenuItem,
    MuiCheckbox: Checkbox,
    MuiFormControlLabel: FormControlLabel,
    MuiAlert: Alert,
    MuiDivider: Divider,
    MuiTableCell: TableCell,
    MuiTableRow: TableRow,
  },
});

export default theme;
