import { createBoxShadow } from 'utils/styles';
import colors from '../base/colors';
import { fontSize, fontWeight } from '../base/typography';
import component from '../component';

const boxShadows = {
  main: `${createBoxShadow([0, 4], [7, -1], '#000', 0.11)}, ${createBoxShadow(
    [0, 2],
    [4, -1],
    '#000',
    0.07
  )}`,

  state: `${createBoxShadow([0, 3], [5, -1], '#000', 0.09)}, ${createBoxShadow(
    [0, 2],
    [5, -1],
    '#000',
    0.07
  )}`,

  mild: createBoxShadow([0, 0], [0, 3.2], colors.info.main, 0.5),
};

export default component('MuiButton', {
  defaultProps: {
    variant: 'contained',
  },

  styleOverrides: {
    root: {
      fontSize: fontSize.xs,
      fontWeight: fontWeight.bold,
      borderRadius: '8px',
      padding: '14px 24px',
      textAlign: 'center',
      transition: 'all 150ms',
      lineHeight: 'normal',

      '&:hover': {
        transform: 'scale(1.02)',
      },

      '&:disabled': {
        pointerEvent: 'none',
        opacity: 0.65,
      },
    },

    contained: {
      backgroundColor: '#fff',
      color: '#fff',
      boxShadow: boxShadows.main,

      '&:hover': {
        backgroundColor: '#fff',
        boxShadow: boxShadows.state,
      },

      '&:focus': {
        boxShadow: boxShadows.state,
      },

      '&:active, &:active:focus, &:active:hover': {
        opacity: 0.85,
        boxShadow: boxShadows.state,
      },

      '&:disabled': {
        boxShadow: boxShadows.main,
      },
    },

    containedSizeSmall: {
      padding: '8px 32px',
      fontSize: fontSize.xs,
    },

    containedSizeLarge: {
      padding: '14px 64px',
      fontSize: fontSize.sm,
    },

    containedPrimary: {
      backgroundColor: colors.info.main,

      '&:hover': {
        backgroundColor: colors.info.main,
      },

      '&:focus:not(:hover)': {
        backgroundColor: colors.info.light,
      },
    },

    containedSecondary: {
      backgroundColor: colors.secondary.main,

      '&:hover': {
        backgroundColor: colors.secondary.main,
      },

      '&:focus:not(:hover)': {
        backgroundColor: colors.secondary.focus,
      },
    },
  },
});
