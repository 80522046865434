import { Box } from '@mui/material';

import { MenuProvider } from 'contexts/MenuContext';
import Main from './Main';
import Sidenav from './Sidenav';

export default function Layout({ children }: React.PropsWithChildren<unknown>) {
  return (
    <MenuProvider>
      <Box display="flex">
        <Sidenav />
        <Main>{children}</Main>
      </Box>
    </MenuProvider>
  );
}
