import Box from '@mui/material/Box';

import { createStyles } from 'utils/styles';
import Header from './Header';
import Footer from './Footer';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
    p: 2,
    overflow: 'hidden',
  },

  content: {
    flex: '1 0 auto',
    overflow: 'auto',
    width: '100%', // Set content width to 100%
  },
});

export default function Main({ children }: React.PropsWithChildren<unknown>) {
  return (
    <Box sx={styles.container}>
      <Header />
      <Box sx={styles.content}>{children}</Box>
      <Footer />
    </Box>
  );
}
