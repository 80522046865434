import component from '../component';

export default component('MuiToolbar', {
  styleOverrides: {
    root: ({ theme }) => ({
      [theme.breakpoints.up('xs')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },

      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    }),
  },
});
