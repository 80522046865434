import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import NotFoundIllustration from 'components/NotFoundIllustration';
import { createStyles } from 'utils/styles';

const styles = createStyles({
  container: {
    maxWidth: ['80%', '80%', '80%', '50%'],
    mx: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    py: 4,
    px: [0, 0, 4],
  },

  title: theme => ({
    mt: [0, 2],
    fontSize: theme.typography.pxToRem(70),
  }),

  text: theme => ({
    mt: 1,
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(18),
  }),
});

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <Box sx={styles.container}>
      <Box width="100%" maxWidth="500px">
        <NotFoundIllustration />
      </Box>
      <Typography variant="h1" sx={styles.title}>
        404
      </Typography>
      <Typography color="secondary" sx={styles.text}>
        {t('common:pageNotFound')}
      </Typography>
    </Box>
  );
}
