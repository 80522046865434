import { createContext, useMemo, useState } from 'react';
import type { AlertColor } from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import Notification from 'components/Notification';
import useSafeContext from 'hooks/useSafeContext';
import { createStyles } from 'utils/styles';

interface Notification {
  type: AlertColor;
  message: string;
}

interface Context {
  add(value: Notification): void;
}

const styles = createStyles({
  position: 'fixed',
  left: 0,
  px: [1, 1, 3],
  bottom: '24px',
  maxWidth: '420px',
  zIndex: 2000,
});

const NotificationContext = createContext<Context | undefined>(undefined);

export function NotificationProvider({ children }: React.PropsWithChildren<unknown>) {
  const [state, setState] = useState<(Notification & { id: number })[]>([]);

  function removeNotification(id: number) {
    setState(prev => prev.filter(item => item.id !== id));
  }

  const value = useMemo<Context>(
    () => ({
      add(notification) {
        setState(prev => [...prev, { ...notification, id: Date.now() }]);
      },
    }),
    []
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
      <Stack spacing={1} sx={styles}>
        {state.slice(0, 3).map(({ id, message, type }) => (
          <Notification key={id} severity={type} onClose={() => removeNotification(id)}>
            {message}
          </Notification>
        ))}
      </Stack>
    </NotificationContext.Provider>
  );
}

export function useNotification() {
  return useSafeContext(NotificationContext);
}
