export interface Route {
  path: string;
  route: string;
  index?: boolean;
  component?: any;
}

export const PUBLIC_ROUTES: Array<Route> = [
  {
    path: '/',
    route: 'sign-in',
  },
  {
    path: '/login',
    route: 'sign-in',
  },
  {
    path: '/sign-up',
    route: 'sign-up',
  },
  {
    path: '/email-confirmation',
    route: 'email-confirmation',
  },
  {
    path: '/forget-password',
    route: 'forget-password',
  },
  {
    path: '/password-reset',
    route: 'reset-password',
  },
];

export const PRIVATE_ROUTES: Array<Route> = [
  {
    path: '/settings/organization-profile',
    route: 'organization-profile',
  },
  {
    path: '/customers',
    route: 'customers',
  },
  {
    path: '/customers/create',
    route: 'customers/create',
  },
  {
    path: '/customers/create',
    route: 'customers/create',
  },
  {
    path: '/clients',
    route: 'clients/list',
  },
  {
    path: '/clients/create',
    route: 'clients/create',
  },
  {
    path: '/clients/:clientId',
    route: 'clients/details',
  },
  {
    path: '/clients/edit',
    route: 'clients/edit',
  },
  {
    path: '/invoices',
    route: 'invoices/list',
  },
  {
    path: '/invoices/domestic',
    route: 'invoices/domestic-invoices/list',
  },
  {
    path: '/invoices/foreign',
    route: 'invoices/foreign-invoices/list',
  },
  {
    path: '/invoices/edit',
    route: 'invoices/edit',
  },
  {
    path: '/invoices/domestic/edit',
    route: 'invoices/domestic-invoices/edit',
  },
  {
    path: '/invoices/foreign/edit',
    route: 'invoices/foreign-invoices/edit',
  },
  {
    path: '/',
    route: 'invoices/domestic-invoices/list',
  },
  {
    path: '/invoices/create',
    route: 'invoices/create',
  },
  {
    path: '/invoices/domestic/create',
    route: 'invoices/domestic-invoices/create',
  },
  {
    path: '/invoices/foreign/create',
    route: 'invoices/foreign-invoices/create',
  },
  {
    path: '/invoices/sendTo',
    route: 'invoices/sendTo',
  },
  {
    path: '/banks',
    route: 'banks/list',
  },
  {
    path: '/banks/create',
    route: 'banks/create',
  },
  {
    path: '/banks/edit',
    route: 'banks/edit',
  },
  {
    path: '/companies',
    route: 'companies/companies',
  },
  {
    path: '/companies/create',
    route: 'companies/create',
  },
  {
    path: '/companies/edit',
    route: 'companies/edit',
  },
  {
    path: '/reports',
    route: 'reports/list',
  },
];
