import ApiService from './ApiService';
import { User } from './AuthService';

export interface Profile {
  id?: string | null;
  additionalFields?: Record<string, string>;
  address: string | null;
  bankAccount: string | null;
  depositorBank: string | null;
  fax?: string | null;
  industry: string | null;
  legalForm: string | null;
  phone: string | null;
  pin?: string | null;
  place: string | null;
  tin?: string | null;
  title: string | null;
  user?: User;
  username?: string;
  name?: string;
  displayName?: string;
  parentCompany?: {
    id: string;
  };
}

const ClientService = {
  get() {
    return ApiService.get<Profile>('/user/profile');
  },

  update(
    data: Omit<Profile, 'username' | 'additionalFields'> & {
      additionalFields: Record<string, string>;
    }
  ) {
    return ApiService.post('/user/profile', data);
  },
  getClients(companyId: string) {
    return ApiService.get(`companies/${companyId}/clients/`);
  },

  createClient(
    data: Omit<any, 'companyId'> & {
      companyId: string;
    }
  ) {
    return data.isInvite
      ? ApiService.post(`companies/${data.companyId}/clients/?invite=true`, data)
      : ApiService.post(`companies/${data.companyId}/clients/`, data);
  },
  updateClientById(
    data: Omit<any, 'companyId'> & {
      companyId: string;
    }
  ) {
    return ApiService.post(`companies/${data.companyId}/clients/${data.id}`, data);
  },
  getByCompanyId(query: string) {
    return ApiService.get(`/ujp/search?q=${query}`);
  },

  createCompany(data: any) {
    return ApiService.post(`companies/`, data);
  },
  updateCompany(
    data: Omit<any, 'companyId'> & {
      companyId: string;
    }
  ) {
    return ApiService.post(`companies/${data.companyId}`, data);
  },
  getAllCompanies() {
    return ApiService.get(`/companies/`);
  },
  getInvoices(companyId: string) {
    return ApiService.get(`companies/${companyId}/invoices/`);
  },
  getInvoicesWithCurrency(companyId: string, currency: string) {
    return ApiService.get(`companies/${companyId}/invoices/?currency=${currency}`);
  },
  getSharedInvoices(companyId: string) {
    return ApiService.get(`companies/${companyId}/invoices/shared`);
  },
  getNextInvoiceId(companyId: string) {
    return ApiService.get(`companies/${companyId}/invoices/next_id`);
  },
  getNextInvoiceId2(companyId: string) {
    return ApiService.get(`companies/${companyId}/invoices/next_id2`);
  },
  createInvoice(data: any) {
    return ApiService.post(`companies/${data.companyId}/invoices/`, data);
  },

  updateInvoice(
    data: Omit<any, 'companyId'> & {
      companyId: string;
    }
  ) {
    return ApiService.post(`companies/${data.companyId}/invoices/`, data);
  },

  getInvoiceAttachments(companyId: string, invoiceId: string) {
    return ApiService.get(`companies/${companyId}/invoices/${invoiceId}/attachments/`);
  },

  getBanks(companyId: string) {
    return ApiService.get(`companies/${companyId}/bank_accounts/`);
  },

  createBankAccount(data: any) {
    return ApiService.post(`companies/${data.companyId}/bank_accounts/`, data);
  },
  updateBank(
    data: Omit<any, 'companyId'> & {
      companyId: string;
    }
  ) {
    return ApiService.post(`companies/${data.companyId}/bank_accounts/`, data);
  },
  deleteBank(data: any) {
    return ApiService.delete(`companies/${data.companyId}/bank_accounts/${data.bankId}`);
  },

  getNotifications(companyId: string) {
    return ApiService.get(`companies/${companyId}/connections/`);
  },

  updateClient(
    data: Omit<Profile, 'username' | 'additionalFields'> & {
      additionalFields: Record<string, string>;
    }
  ) {
    return ApiService.post('/user/profile', data);
  },
  addComment(
    data: Omit<any, 'companyId'> & {
      companyId: string;
    }
  ) {
    return ApiService.post(
      `companies/${data.companyId}/invoices/${data.invoiceId}/comments/`,
      data
    );
  },
  getComments(companyId: string, invoiceId: string) {
    return ApiService.get(`companies/${companyId}/invoices/${invoiceId}/comments/`);
  },
  getMonthlyReports(companyId: string) {
    return ApiService.get(`companies/${companyId}/reports/monthly/`);
  },
  getQuarterlyReports(companyId: string) {
    return ApiService.get(`companies/${companyId}/reports/quarterly/`);
  },
  getYearlyReports(companyId: string) {
    return ApiService.get(`companies/${companyId}/reports/yearly/`);
  },
};

export default ClientService;
