import { BASE_FONT_SIZE } from 'config/settings';
import { pxToRem } from 'utils/styles';
import colors from './colors';

export const fontSize = {
  xxs: pxToRem(10.5),
  xs: pxToRem(12),
  sm: pxToRem(14),
  md: pxToRem(16),
  lg: pxToRem(18),
  xl: pxToRem(20),
};

export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
};

const baseHeadingProps = {
  color: colors.dark.main,
  fontWeight: fontWeight.medium,
};

const typography = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  htmlFontSize: BASE_FONT_SIZE,
  fontWeightLight: fontWeight.light,
  fontWeightRegular: fontWeight.regular,
  fontWeightMedium: fontWeight.medium,
  fontWeightBold: fontWeight.bold,

  h1: {
    fontSize: pxToRem(48),
    lineHeight: 1.25,
    ...baseHeadingProps,
  },

  h2: {
    fontSize: pxToRem(36),
    lineHeight: 1.3,
    ...baseHeadingProps,
  },

  h3: {
    fontSize: pxToRem(30),
    lineHeight: 1.375,
    ...baseHeadingProps,
  },

  h4: {
    fontSize: pxToRem(24),
    lineHeight: 1.375,
    ...baseHeadingProps,
  },

  h5: {
    fontSize: pxToRem(20),
    lineHeight: 1.375,
    ...baseHeadingProps,
  },

  h6: {
    fontSize: pxToRem(16),
    lineHeight: 1.625,
    ...baseHeadingProps,
  },

  subtitle1: {
    fontSize: fontSize.xl,
    fontWeight: fontWeight.regular,
    lineHeight: 1.625,
  },

  subtitle2: {
    fontSize: fontSize.md,
    fontWeight: fontWeight.medium,
    lineHeight: 1.6,
  },

  body1: {
    fontSize: fontSize.md,
    fontWeight: fontWeight.regular,
    lineHeight: 'normal',
  },

  body2: {
    fontSize: fontSize.sm,
    fontWeight: fontWeight.regular,
    lineHeight: 'normal',
  },
};

export default typography;
