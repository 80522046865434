import Box from '@mui/material/Box';

import { createStyles } from 'utils/styles';

const styles = createStyles({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export default function Container({ children }: React.PropsWithChildren<unknown>) {
  return <Box sx={styles}>{children}</Box>;
}
