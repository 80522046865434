import boxShadows from '../base/boxShadows';
import component from '../component';

export default component('MuiListItemIcon', {
  styleOverrides: {
    root: {
      width: 32,
      height: 32,
      minWidth: 'auto',
      borderRadius: '8px',
      fontSize: 14,
      backgroundColor: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'inherit',
      boxShadow: boxShadows.md,
    },
  },
});
