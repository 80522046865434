import { useEffect, useRef, useState } from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';
import Fade from '@mui/material/Fade';

interface Props {
  severity: AlertColor;
  onClose(): void;
}

const DELAY = 10_000;

class Timer {
  private timerId?: number;
  private remaining: number;
  private startedAt = 0;

  constructor(private readonly callback: Function, delay: number) {
    this.remaining = delay;
  }

  pause() {
    window.clearTimeout(this.timerId);

    this.timerId = undefined;
    this.remaining -= Date.now() - this.startedAt;
  }

  start() {
    if (this.timerId) {
      return;
    }

    this.startedAt = Date.now();
    this.timerId = window.setTimeout(this.callback, this.remaining);
  }

  destroy() {
    window.clearTimeout(this.timerId);
  }
}

export default function Notification({
  onClose,
  children,
  severity,
}: React.PropsWithChildren<Props>) {
  const timer = useRef<Timer>();
  const [isOpen, setOpen] = useState(true);

  function handleMouseOver() {
    timer.current?.pause();
  }

  function handleMouseOut() {
    timer.current?.start();
  }

  useEffect(() => {
    if (!timer.current) {
      timer.current = new Timer(() => setOpen(false), DELAY);
    }

    timer.current.start();

    return () => {
      timer.current?.destroy();
    };
  }, []);

  return (
    <Fade
      in={isOpen}
      mountOnEnter
      unmountOnExit
      onMouseOver={handleMouseOver}
      onExited={onClose}
      onMouseOut={handleMouseOut}
    >
      <Alert severity={severity} onClose={() => setOpen(false)}>
        {children}
      </Alert>
    </Fade>
  );
}
