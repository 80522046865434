import Box from '@mui/material/Box';

import { createStyles } from 'utils/styles';

const styles = createStyles(theme => ({
  width: '100%',
  height: '430px',
  background: `center center / cover no-repeat, url(/images/background-business-app.jpg) center center / cover no-repeat`,
  borderRadius: '12px',
}));

export default function Hero({ children }: React.PropsWithChildren<unknown>) {
  return <Box sx={styles}>{children}</Box>;
}
